ul li p,
ul ol p{
    margin-bottom: 0;
    // line-height: .8rem;
}

ul li{
    margin-bottom: 1rem;
}


span.subheader{
    font-family: $body-font-family;
    display: block;
    font-weight: 600;
    // margin-top: rem-calc(20px);
}



.page-title,
h1.large,
h1 span.large,
.page-header h1
{
    font-size: rem-calc(map-deep-get($header-styles, small, h1, font-size)*2);

    span.subheader{
        font-size: rem-calc(map-deep-get($header-styles, small, h2, font-size));
    }


    @include breakpoint(large){
        font-size: rem-calc(map-deep-get($header-styles, medium, h1, font-size)*2); // rem-calc(80px);
        span.subheader{
            font-size: rem-calc(map-deep-get($header-styles, medium, h2, font-size));
        }
    }
}

h2.large,
h2 span.large
{
    font-size: rem-calc(map-deep-get($header-styles, small, h2, font-size)*2);

    span.subheader{
        font-size: rem-calc(map-deep-get($header-styles, small, h3, font-size));
    }


    @include breakpoint(large){
        font-size: rem-calc(map-deep-get($header-styles, medium, h2, font-size)*2); // rem-calc(80px);
        span.subheader{
            font-size: rem-calc(map-deep-get($header-styles, medium, h3, font-size));
        }
    }
}

h3.large,
h3 span.large
{
    font-size: rem-calc(map-deep-get($header-styles, small, h3, font-size)*2);

    span.subheader{
        font-size: rem-calc(map-deep-get($header-styles, small, h4, font-size));
    }


    @include breakpoint(large){
        font-size: rem-calc(map-deep-get($header-styles, medium, h3, font-size)*2); // rem-calc(80px);
        span.subheader{
            font-size: rem-calc(map-deep-get($header-styles, medium, h4, font-size));
        }
    }
}

h4.large,
h4 span.large
{
    font-size: rem-calc(map-deep-get($header-styles, small, h4, font-size)*2);

    span.subheader{
        font-size: rem-calc(map-deep-get($header-styles, small, h5, font-size));
    }


    @include breakpoint(large){
        font-size: rem-calc(map-deep-get($header-styles, medium, h4, font-size)*2); // rem-calc(80px);
        span.subheader{
            font-size: rem-calc(map-deep-get($header-styles, medium, h5, font-size));
        }
    }
}

h5.large,
h5 span.large
{
    font-size: rem-calc(map-deep-get($header-styles, small, h5, font-size)*2);

    span.subheader{
        font-size: rem-calc(map-deep-get($header-styles, small, h6, font-size));
    }


    @include breakpoint(large){
        font-size: rem-calc(map-deep-get($header-styles, medium, h5, font-size)*2); // rem-calc(80px);
        span.subheader{
            font-size: rem-calc(map-deep-get($header-styles, medium, h6, font-size));
        }
    }
}

h6.large,
h6 span.large
{
    font-size: rem-calc(map-deep-get($header-styles, small, h6, font-size)*2);

    span.subheader{
        font-size: rem-calc(map-deep-get($header-styles, small, h6, font-size));
    }


    @include breakpoint(large){
        font-size: rem-calc(map-deep-get($header-styles, medium, h6, font-size)*2); // rem-calc(80px);
        span.subheader{
            font-size: rem-calc(map-deep-get($header-styles, medium, h6, font-size));
        }
    }
}
