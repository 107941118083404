header{
    .grid-container{
        padding-top: 0;
        padding-bottom: 0;
    }
}


header .logo{
    svg, img{
        width: 100%;
        height: 100%;
    }
}


header {
    // z-index: 2000;

    position: relative;

    // @include breakpoint(large){
    //     margin-top: rem-calc(20px);
    //     margin-bottom: rem-calc(60px);
    // }
    //
    // @include breakpoint(medium){
    //     padding-left: rem-calc(50px);
    //     padding-right: rem-calc(50px);
    // }
    //
    // @include breakpoint(large){
    //     padding-left: rem-calc(100px);
    //     padding-right: rem-calc(100px);
    // }

	//transition: all 600ms cubic-bezier(0.600, 0.300, 0.000, 1.000); /* custom */
    transition: all 400ms ease-in-out;

    // padding:15px 0;
    background: none;

    // @include breakpoint(medium){
    //     padding:25px 0;
    // }

    top: 0;
    left: 0;
    right: 0;



    a{
        position: relative;
        display: inline-block;
        // left: 50%;
        // transform: translateX(-50%);
        // z-index: 100;
    }


    .logo-container{
        // width:12%;
        @include breakpoint(medium){
            text-align: right;
            padding-right: 30px;
        }
    }


    .logo {

        margin-top: 20px;
        margin-bottom:20px;
        transition: all 10ms cubic-bezier(0, 0, 0.500, 0.750);

        // width: 150px;
        // height: 44px;

        width: 100%;
        max-width: 105px;

        display: inline-block;

        // @include breakpoint(medium){
        //     width: 240px;
        //     height: 70px;
        // }

    }


    // @include breakpoint(medium){
    //     .grid-container{
    //         z-index: 2000;
    //     }
    // }
}

header.header-shrink{
    position: fixed;
    z-index: 999;
    padding-top:5px;
    // padding-bottom:5px;

    background: $white;
    // box-shadow: 15px 15px 45px 0px rgba(10, 10, 10, 0.12);

    .menu-toggle{
        // box-shadow: 15px 15px 45px 0px rgba(10, 10, 10, 0.12);
    }

    .logo{
        // opacity: 0;
        width: 150px;
        // @include breakpoint(medium){
        //     width: 80px;
        // }
        // @include breakpoint(landscape){
        //     @include breakpoint(medium only){
        //         width:60px;
        //     }
        //     @include breakpoint(small only){
        //         width:50px;
        //     }
        // }
    }

}
