#prevBtn {
    background-color: #b5b5b5;
}

.reveal .interestlist-form{
    &.grid-container, .grid-margin-x
    {
        padding-top: 0;
    }
}



.interestlist-form{

    width: 100%;

    .projects{
        margin-bottom: rem-calc(60px);
    }

    .error{
        opacity: 1;
        margin-bottom: rem-calc(60px);
        display: none;
        // transition: opacity .2s;
    }

    .tab:nth-child(n+2){
        @include breakpoint(small only){
            margin-top: rem-calc(50px);
        }

        @include breakpoint(xxlarge){
            margin-top: rem-calc(150px);
        }
    }

    label{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    [type='checkbox']{
        display: none;
    }


    ul.no-bullet{

        display: grid;
        // grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 30px;


        @include breakpoint(medium){
            margin-top:rem-calc(60px);
            grid-template-columns: repeat(2, 1fr);
        }

        @include breakpoint(large){
            grid-template-columns: repeat(3, 1fr);
        }
    }

    ul.no-bullet li {
        display: flex;
        margin-bottom: 0;
        text-align: left;
        // margin-bottom:rem-calc(20px);
    }

    // .cell {
    //     // text-align: left;
    //     margin-bottom:rem-calc(60px);
    // }

    [type='checkbox'] + label {
        background: #fff;
        // display: inline-block;
        // margin-left: -15px;
        padding: 20px;//20px 0px 20px 0px;
        text-align: center;
        border: 3px solid transparent;
        transition:all 100ms ease-in-out;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        // @include breakpoint(medium){
        //     width: 350px;
        // }

        &.is-invalid-label{
            color:inherit;
        }
    }

    .filled + label
    //,label:hover
    {
        border: 3px solid $white;//scale-color(get-color(success), $lightness: -15%);
        // border: 1px solid $black;
        // background-color: $medium-gray;
    }

    .filled + label
    {
        background-color: #004d33;//#ecfff3;//get-color(success);
        color: $white;
        // border: 1px solid scale-color(get-color(success), $lightness: -15%);
        //font-weight: bold;
    }


    /* Hide all steps by default: */
    .tab {
      display: none;
    }

    .button-container{
        width: 100%;
        margin-bottom: rem-calc(map-get($grid-margin-gutters , medium));
        padding-top: 0;
    }
}

//
// --- Support for IE
//
html.no-cssgrid.flexbox .reveal .interestlist-form{

    ul.no-bullet{

        display: flex;
        flex-flow: wrap;


        @include breakpoint(medium){
            margin-top:rem-calc(60px);
        }
    }

    ul.no-bullet li {
        // display: block;
        // @include breakpoint(medium){
            display: inline-block;
        // }

        // text-align: left;
        margin: 0 rem-calc(10px) rem-calc(20px) rem-calc(10px);
    }

    label{
        // display: inline-block;
        display: flex;
        align-items: center;
        justify-content:center;
        vertical-align: top;//baseline;
    }

    [type="checkbox"] + label {
        padding: 20px;//20px 0px 20px 0px;
        text-align: center;

        width: 310px;
        @include breakpoint(medium){
            width: 350px;
        }
    }

}

// Label animation in form
// https://codepen.io/nathanlong/pen/kkLKrL

.form-group {
  position:relative;

  margin-top: 30px;

  // & + .form-group {
  //   margin-top: 30px;
  // }
}

.form-group label {
  position: absolute;
  left: 0;
  top: 10px;
  color: $black;
  z-index: 10;
  transition: transform 150ms ease-out, font-size 150ms ease-out;
}

.reveal .form-group label {
    background-color: $medium-gray;
}

.focused label {
  transform: translateY(-125%);
  font-size: .75em;
}

.form-group input{
  position: relative;
  padding: 12px 0px 5px 0;
  width: 100%;
  outline: 0;
  border: 0;
  box-shadow: 0 1px 0 0 $black;
  transition: box-shadow 150ms ease-out;

  &:focus {
    box-shadow: 0 2px 0 0 $black;
  }
}

.form-group input.invalid{
  box-shadow: 0 1px 0 0 get-color(alert);
  &:focus {
    box-shadow: 0 2px 0 0 get-color(alert);
  }
}

.form-group input.filled,
.form-group input.invalid.filled{
    box-shadow: 0 1px 0 0 get-color(success);
    &:focus {
        box-shadow: 0 2px 0 0 get-color(success);
    }
}

.form-input.filled {
  box-shadow: 0 2px 0 0 lightgreen;
}






/* Make circles that indicate the steps of the form: */
#form-steps {
    text-align:center;
    margin-bottom:40px;
}

.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: $white;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

/* Mark the active step: */
.step.active {
  opacity: 1;
}

/* Mark the steps that are finished and valid: */
.step.finish {
  background-color: get-color(success);
}

.mce-information{
    margin-top:40px;
    margin-bottom:40px;
}

// MailChimp result
#subscribe-result{
    text-align: center;
    opacity: 0;
    transition:all 100ms ease-in-out;
    margin-top:40px;
    margin-bottom:40px;
    p{
        padding: 15px;
        font-weight: bold;
    }
    p, a{
        color: $white;
    }

    a{
        display: inline-block;
        text-decoration: underline;
    }

    &.subscribe-error,
    &.subscribe-success{
        opacity: 1;
    }

    &.subscribe-error{
        background-color:get-color(alert);
    }
    &.subscribe-success{
        background-color:get-color(success);
    }
}
