
.menu-container{
    @include flex;

    @include flex-align($y: middle);

    @include breakpoint(large){
        @include flex-align($y: bottom);
    }
}

//Remove outline after click
#main-nav a:active,
#main-nav a:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none
}


#menu
{
    @include flex;
    width:100%;

    // @include breakpoint(medium down){
    //     @include flex-align($x: right);
    // }
    .toggle-button
    {
        display: block;
        @include breakpoint(large){
            display: none;
        }
    }

    ul.menu.horizontal
    {
        display: none;
        @include breakpoint(large){
            display: flex;
        }

        li ul{
            display: none;
        }
    }
}

.menu.horizontal > li {
    margin-bottom: 0;
    margin-right: rem-calc(5px);
    &:last-of-type {
        margin-right: 0;
    }

    &:first-of-type a {
        padding-left: 0;
    }

    a{
        font-size: rem-calc(14px);//rem-calc(13px);
        // @include breakpoint(large){
        //     font-size: rem-calc(14px);
        // }
        text-transform: uppercase;
        letter-spacing: .05rem;

        &:hover, &:focus {
            color: inherit;
        }

        padding-bottom: rem-calc(20px);
        transition: all 400ms ease-in-out;
    }

    // &:hover > a,
    // &:focus > a,
    &.is-active > a {
        font-weight:600;
    }

    &:hover > a,
    &:focus > a {
        color:scale-color($black, $lightness: -14%);
    }
}

// .no-scroll .menu.horizontal > li.is-active > a{
//     border-bottom: 1px solid $black;
// }

// Don't show drilldown in root
body > #the-drill-down-nav { //.is-drilldown
    @include breakpoint(large){
        display: none;
    }
}



#menu-overlay {
    .grid-container{
        @include breakpoint(medium){
            min-height: 100%;
        }
    }

    .grid-x{
        min-height: 100%;
        @include breakpoint(medium){
            min-height: 80%;
        }
    }
}

#menu-overlay {
    min-height:80%;
}




#main-nav {
    @include breakpoint(medium down){
        padding-top: 50px;
    }
}

// Menu on large devices up
#main-nav ul:not(#drill-down)
{

    li {

        overflow: hidden;
        position: relative;

        margin-bottom: rem-calc(20px);

        @include breakpoint(medium){
            margin-bottom:: rem-calc(30px);
        }

        a:not(.button){

            transition:color 80ms ease-in-out;

            font-family: $header-font-family;
            font-size: rem-calc(40px);

            @include breakpoint(large){
                font-size: rem-calc(60px);
            }

            @include breakpoint(xlarge){
                font-size: rem-calc(80px);
            }
        }

        a.button{
            display: inline-block;
        }

    }

    .menu.nested{
        margin-left: 0;
    }
}

// Menu on medium devices down
#main-nav ul#drill-down {
    li{

        a:not(.button){


            font-family: $header-font-family;
            font-size: rem-calc(30px);

            @include breakpoint(medium){
                font-size: rem-calc(60px);
            }

            @include breakpoint(medium down){
                @include breakpoint(landscape) {
                  // CSS for landscape-oriented devices only
                  font-size: rem-calc(30px);
                }
            }

            @include breakpoint(large){
                font-size: rem-calc(80px);
                @include breakpoint(landscape) {
                  // CSS for landscape-oriented devices only
                  font-size: rem-calc(40px);
                }
            }
        }



        a.button {
            margin-top: 50px;
            width: 50%;
        }


        &.is-drilldown-submenu-parent > a:after {
            border: none;
        }


        // &.is-drilldown-submenu-parent > a:hover
        // {
        //     background-image: url(../img/icons/chevron_white.svg);
        //     background-repeat: no-repeat;
        //     background-size: 9px 18px;
        //     background-position: calc(100% - 30px) center;
        //
        // }


        // & > a:hover,
        // & > a:focus
        // {
        //     background-color: lighten($primary-color, 5);
        // }


        .js-drilldown-back
        {
            // position: absolute;
            // left: 0;

            & > a{
                font-size: rem-calc(15px);
                // width: 40px;
                // height: 40px;
                //
                // background-image: url(../img/icons/chevron_white.svg);
                // background-repeat: no-repeat;
                // background-size: 9px 18px;
                // background-position: center;
                //
                // transform: rotate(180deg);
                // transition: background-color 0.25s ease-out, background-image 0.25s ease-out;
                //
                // &:hover{
                //     background-color: lighten($primary-color, 10);
                //     // background-image: url(../img/icons/chevron_red.svg);
                // }
            }

            & > a::before {
                border: none;
                vertical-align: top;

                // background-image: url(../img/icons/chevron_white.svg);
                // background-size: auto;
                // background-repeat: no-repeat;
                // background-size: 9px 18px;
                // transform: rotate(180deg);
            }
        }

    }


    li a.new-back span
    {
        margin-right: rem-calc(10px);
        line-height: 0;
        vertical-align: baseline;
        font-size: rem-calc(20px);
        font-family: arial,sans-serif;
    }

}


.drilldown a.button{
    background: #fff;
}


#main-nav li.red-notis{
    &:after{
        content: "1";
        background-color:$alert-color;
        color: #fff;
        display: inline-block;

        min-width: 2.1em;
        padding: 0.3em;
        border-radius: 50%;
        font-size: 0.8rem;
        text-align: center;
        font-weight: 800;

        // padding: 11px 8px;
        // line-height: 0;
        // letter-spacing: 0;
        // color: #fff;
        // font-weight: 600;
        // font-size: rem-calc(10px);
        // border-radius: 12px;
        position: absolute;

        transform:scale(0);

        top: 10px;
        left:230px;

        @include breakpoint(medium){
            top: 5px;
            left:380px;
        }

        @include breakpoint(large){
            top: 10px;
            left:450px;
        }

    }

}

.is-reveal-open #main-nav li.red-notis:after{
    transform:scale(1);
    transition: all 0.3s cubic-bezier(0.600, 0.000, 0.200, 1.000);
    transition-timing-function: cubic-bezier(0.600, 0.000, 0.200, 1.000);
    transition-delay: 1s;
}

// For test
// #main-nav .menu.vertical li a:after {
//     content: "";
//     display: block;
//     background: #ff0000;
//     position: absolute;
//     top: 0;
//     right: 0;
//     width: 40%;
//     height: 100%;
// }
