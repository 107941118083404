/*----------------------------------------------

LIGHTGALLERY / LIGHTSLIDER

------------------------------------------------*/

.df-gallery {
    .lg-outer .lg-thumb-item.active,
    .lg-outer .lg-thumb-item:hover {
        border-color: $secondary-color;
    }
}


ul.light-slidergallery {
    margin-left: 0;
    margin-bottom: 0;
    list-style: none;

    &.no-slideshow{
        width: 100%;
    }

    img{
        width: 100%;
        cursor: pointer;
    }

    li{
       margin-bottom: 0;
   }
}


// .fit-image{
//     ul.light-slidergallery {
//         height:100%;
//
//         li{
//            height:100%;
//        }
//     }
//
//     div.lSSlideOuter,
//     div.lSSlideWrapper
//     {
//         height:100%;
//     }
// }

// .fit-image{
//     ul.light-slidergallery {
//         height:100%;
//
//         li{
//            height:100%;
//        }
//     }
// }

@include breakpoint(medium){
    .vh100 .fit-image{
        ul.light-slidergallery {
            height:100vh;
            li{
               height:100vh;
           }
        }
    }

    .vh90 .fit-image{
        ul.light-slidergallery {
            height:90vh;
            li{
               height:90vh;
           }
        }
    }

    .vh80 .fit-image{
        ul.light-slidergallery {
            height:80vh;
            li{
               height:80vh;
           }
        }
    }

    .vh70 .fit-image{
        ul.light-slidergallery {
            height:70vh;
            li{
               height:70vh;
           }
        }
    }

    .vh60 .fit-image{
        ul.light-slidergallery {
            height:60vh;
            li{
               height:60vh;
           }
        }
    }

    .vh50 .fit-image{
        ul.light-slidergallery {
            height:50vh;
            li{
               height:50vh;
           }
        }
    }

    .vh40 .fit-image{
        ul.light-slidergallery {
            height:40vh;
            li{
               height:40vh;
           }
        }
    }

    .vh30 .fit-image{
        ul.light-slidergallery {
            height:30vh;
            li{
               height:30vh;
           }
        }
    }

    .vh20 .fit-image{
        ul.light-slidergallery {
            height:20vh;
            li{
               height:20vh;
           }
        }
    }

    .vh10 .fit-image{
        ul.light-slidergallery {
            height:10vh;
            li{
               height:10vh;
           }
        }
    }
}



ul.light-slidergallery.no-slideshow > li{
    display: none;
    position: relative;
}

ul.light-slidergallery.no-slideshow > li:first-child{
    display: block;
}

.image-cell ul.light-slidergallery li img{
//.medium-12.image-cell ul.light-slidergallery li img{

    min-height:223px;

    @include breakpoint(medium){
        min-height:472px;
    }

    @include breakpoint(large){
        min-height:auto;
    }
}


// SLIDE NAV ICONS
// ----------------------

.image-fullscreen
{
    min-width:43.75px;
    min-height:43.75px;

    background-image: url(../img/icons/icon-fullscreen.svg);
    background-position: center;
    background-size: 33.75px;
    background-repeat: no-repeat;
    cursor: pointer;

    top: 20px;
    right: 20px;

    @include breakpoint(large){
        display: block;
        right:-60px;
    }

    .badge{
        position: absolute;
        bottom: -8px;
        left: -8px;
        font-weight: bold;
    }
}


//  -- All slide nav icons

.image-fullscreen,
a.arrow.next,
a.arrow.prev
{
    display: none;
    position:absolute;
    z-index: 999;
    @include breakpoint(large){
        display: block;
    }
}


// position all icons on left side, if image is on right side

.image-right .image-fullscreen,
.image-right a.arrow.next,
.image-right a.arrow.prev
{
    right: auto;
    left:-60px;
}




//  -- Slide arrows

a.arrow.next,
a.arrow.prev
{
    width: 30px;
    height: 9px;
    margin-bottom: 0;
    background-image: url(../img/icons/arrow-right.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 27px 8px;

    // @include breakpoint(small only){
    //     padding-left: calc(33.9px + 2.5rem);
    //     background-size: 33.9px 6.15px;
    // }
}



a.arrow.next
{
    @include breakpoint(medium){
        top: 80px;
        right: -60px;
    }
}



a.arrow.prev
{
    transform: rotate(180deg);

    @include breakpoint(medium){
        top: 100px;
        right: -60px;
    }
}



body.lg-on
{
    a.arrow.next,
     a.arrow.prev
    {
        // Hide arrows when lightgallery is active
        display: none;
    }
}



//  -- Slide captions

.slide-caption{
    position: absolute;
    bottom: 0;
    background-color: $white;
    padding: rem-calc(10px) rem-calc(15px);
    width: 100%;

}
