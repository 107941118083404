.employee{
    margin-bottom: rem-calc(map-get($grid-margin-gutters , small));

    @include breakpoint(medium){
        margin-bottom: rem-calc(map-get($grid-margin-gutters , medium));
    }

    @include breakpoint(large){
        margin-bottom: rem-calc(map-get($grid-margin-gutters , large));
    }
}


.employee .image-container{
    margin-bottom: rem-calc(map-get($grid-margin-gutters , small))/2;

    @include breakpoint(medium){
        margin-bottom: rem-calc(map-get($grid-margin-gutters , medium))/2;
    }

    @include breakpoint(large){
        margin-bottom: rem-calc(map-get($grid-margin-gutters , large))/2;
    }
}

.employee p span.name{
    display: block;
}
