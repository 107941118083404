/* TESTS */
.column-info {
    position: absolute;
    background-color: rgba(255,255,255,0.95);
    border: 1px solid #000;
    padding: 20px;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);
}


// TEST
.cell{
    //outline:1px solid #ff0000;
    // height:540px;
}
