/*----------------------------------------------

MENU TOGGLE KNAP – Design Fordi

------------------------------------------------*/


.toggle-button{
    position: absolute;
    right: 0;
    // top: 20px;
    // @include breakpoint(small only){
    //     top: 50px;
    // }

    top: 50%;
    transform: translateY(-50%);

    margin-right: rem-calc(map-get($grid-column-gutter, small))/2;

    @include breakpoint(medium){
        margin-right: rem-calc(map-get($grid-column-gutter, medium))/2;
    }

    @include breakpoint(large){
        margin-right: rem-calc(map-get($grid-column-gutter, large))/2;
    }
}

.menu-toggle {

    display: block;
	cursor: pointer;
	overflow: hidden;

    //transform: translateY(-50%);
    display: block;


    right: 0;
    width: 40px;
	height: 40px;

    // border: 2px solid $secondary-color;
    // border-radius: 30px;

    background-color: $white;
    // box-shadow: 5px 5px 20px 0px rgba($black,.2);

	// transition: height 0.3s ease, transform 0.4s ease;
    transition: all 0.3s ease;

    .menu-icon{
        display: inline-block;
        float: right;
    }
}


/* hamburger icon / animation */
.menu-toggle span.hamburger,
.menu-toggle span.hamburger:after,
.menu-toggle span.hamburger:before {
    content: "";
    display: block;
    height: 3px; //stroke width
    background: $black;
    position: absolute;
    top: 50%; margin-top: -1px;
    left: 50%;

    width: 20px;
    margin-left: -10px; //half the width in minus

    transition: all 0.3s cubic-bezier(0.600, 0.000, 0.200, 1.000);
    transition-timing-function: cubic-bezier(0.600, 0.000, 0.200, 1.000);
    transition-delay: 0.15s;
}

.menu-toggle span.hamburger:before {
    // width: 10px;
    right: 85%;
}

.menu-toggle span.hamburger:after { margin-top: -7px; top: 0; transition-delay: 0.27s; }
.menu-toggle span.hamburger:before { margin-top: 7px; top: 0; transition-delay: 0.2s; }

.is-off-canvas-open .menu-toggle span.hamburger,
.is-off-canvas-open .menu-toggle span.hamburger:after,
.is-off-canvas-open .menu-toggle span.hamburger:before {
	transition-delay: 0.12s;
  	transform: translateX(50px);
}
.is-off-canvas-open .menu-toggle span.hamburger:after { transition-delay: 0s; }
.is-off-canvas-open .menu-toggle span.hamburger:before { transition-delay: 0.07s; }



/* cross icon / animation */
.menu-toggle span.cross:before,
.menu-toggle span.cross:after {
	content: "";
	display: block;
	height: 3px; //stroke width
	background: $black;
	position: absolute;
	top: 50%;
    margin-top: -1.5px;
    left: 50%;

	width: 20px;
    margin-left: -10px; //half the width in minus

	transition: transform 0.3s cubic-bezier(0.600, 0.000, 0.200, 1.000);
	transition-timing-function: cubic-bezier(0.600, 0.000, 0.200, 1.000);
  	transform: translateY(-70px) translateX(-70px) rotate(45deg);
	transition-delay: 0.12s;
}

.menu-toggle span.cross:after {
	transition-delay: 0s;
  	transform: translateY(-70px) translateX(70px) rotate(-45deg);
}

.is-off-canvas-open .menu-toggle span.cross:before {
	transition-delay: 0.12s;
  	transform: rotate(45deg);
}
.is-off-canvas-open .menu-toggle span.cross:after {
	transition-delay: 0.24s;
  	transform: rotate(-45deg) !important;
}
//
// .header-shrink{
//     .menu-toggle span.hamburger,
//     .menu-toggle span.hamburger:after,
//     .menu-toggle span.hamburger:before {
//         background: $black;
//     }
// }

#menu .menu-toggle div:first-child{
    float:right;
    display:inline-block
}

#menu .toggle-button{
    z-index: 999;
}

// #menu .toggle-button.have-notification{
//     &:after{
//         position: absolute;
//         content: "";
//         top: -20px;
//         right: 0px;
//         background-color: $alert-color;
//         width: 10px;
//         height: 10px;
//         border-radius: 5px;
//         transition: transform 0.3s cubic-bezier(0.600, 0.000, 0.200, 1.000);
//         z-index: 999;
//         transform: scale(0);
//     }
//
// }

html.loaded #menu .toggle-button.have-notification:after{
    transform: scale(1);
	transition-delay: 2s;
}

html.loaded .is-off-canvas-open #menu .toggle-button.have-notification:after{
    transform: scale(0);
    transition-delay: .2s;
    // display: none;

}
