.counter-element{
    .counter [data-count]{
        font-size: rem-calc(80px);
    }

    .counter span.subline{
        text-transform: uppercase;
        font-size: rem-calc(20px);
    }

    .counter span:last-of-type{
        font-size: rem-calc(40px);
    }

    span{
        font-family: $header-font-family;
        display: block;
        line-height: 1.1;
    }

}
