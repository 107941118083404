.scroll-down{
    text-align: center;
    left: calc(50% - 10px);
    position: absolute;
    /* display: flex; */
    bottom:50px;
    height:0vh;
    z-index: 9999;
}

// .scroll-down:after{
//     content:"";
//     display:block;
//     width:25px;
//     height: 40px;
//     border: 2px solid #e1e1e1;
//     border-radius: 20px;
// }

.scroll-down-arrow{
    position: absolute;
    top: -50px;
    left: 12px;
    height: 30px;
    width: 1px;
    background: #000;
    animation: animate 2s infinite;
}
.scroll-down-arrow:before{
    content: "";
    position: absolute;
    bottom: 0;
    left: -5px;
    width: 10px;
    height:10px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    transform: rotate(45deg);
}
@keyframes animate{
    0%{
        transform: translateY(0);
        opacity: 0.5;
    }
    50%{
        transform: translateY(40px);
        opacity: 1;
    }
    80%{
        opacity: .1;
    }

    100%{
        transform: translateY(80px);
        opacity: 0;
    }
}
