// @import 'util/mixins';
// @import 'components/mixins';

@charset "UTF-8";



//  1. Global
// ----------------------


a[href^="tel:"]
{
    text-decoration: none;
    display: inline-block;
}

// Remove outline after click
// a:active, a:focus {
//   outline: 0;
//   border: none;
//   -moz-outline-style: none
// }



.header-font{
    font-family: $header-font-family;

    h1, .h1
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    p,
    ul li,
    ol li{
        font-family: $header-font-family;
    }
}

h1, .h1
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
p,
ul li,
ol li
{
    .header-font{
        font-family: $header-font-family;
    }
}


.body-font{
    font-family: $body-font-family;

    h1, .h1
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    p,
    ul li,
    ol li{
        font-family: $body-font-family;
    }
}

h1, .h1
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6,
p,
ul li,
ol li
{
    .body-font{
        font-family: $body-font-family;
    }
}


//DELETE THIS??
// * > :last-child { margin-bottom: 0 !important; }

p{
    letter-spacing: .05rem;
}

p a:hover {
    text-decoration: underline;
}

li a{
    color: $black
}




// p,
// ul,
// ol{
//     &:last-of-type{
//         margin-bottom: 0;
//     }
// }



//  5. Typography Helpers
// ----------------------

strong,
.strong {
    font-weight: 700;
}


.extra-strong {
    font-weight: 900;
}


.uppercase {
    text-transform: uppercase;
}


/* - Text Colors - */

.primary-color {
    color: get-color(primary);
}


.secondary-color {
    color: get-color(secondary);
}


.white-color {
    color: $white;
}


.black-color {
    color:$black;
}


.force-white-text,
.white:not(.button)
{
    h1, .h1
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    p, ul li, ol li{
        color: $white !important;
    }

    color: $white !important;
}


.force-black-text,
.black:not(.button)
{
    h1, .h1
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    p,
    ul li,
    ol li
    {
        color: $black !important;
    }

    color: $black !important;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: $header-margin-bottom;
}


hr + .cell .section-header{
    // padding-bottom: 90px;
    padding-bottom: 30px;
}

.section-header{
    color: $primary-color;
}


.primary-background .section-header{
    color: $white;
}



/* - Links - */

a img {
    border: none;
}


a {
    i.fa
    {
        font-size: .95rem;
        padding-left: .5rem;
        transition: all .25s ease-in-out;
    }

    &:hover
    {
        i.fa {
            padding-left:1rem;
        }
    }
}



/* - Paragraph - */
p:last-of-type{ //.cell
    margin-bottom: 0;
    &+ul,
    &+img{
        margin-top: $paragraph-margin-bottom;
    }
}

.cell > img + p:last-of-type{
    margin-top: $paragraph-margin-bottom;
}



/* - HR - */

hr
{
    display: block;
    width: 100%;

    margin: rem-calc(25px) rem-calc(map-get($grid-column-gutter, small))/2 rem-calc(45px);

    @include breakpoint(medium){
        // margin: rem-calc(130px) rem-calc(map-get($grid-column-gutter, medium))/2 rem-calc(190px);
        margin: rem-calc(130px) rem-calc(map-get($grid-column-gutter, medium))/2 rem-calc(120px);
    }
}

.cell hr{

    @include breakpoint(small only){
        display: none;
    }

    margin: 0;
    left: -90px;
    top: 16px;
    position: absolute;
    width: 15%;
}

.primary-background
{
    hr{
        border: 1px solid $white;
    }
}

.cell.image-cell hr{

    @include breakpoint(small only){
        display: block;
    }

    margin: rem-calc(45px) 0 calc(45px - 20px);

    left: auto;
    top: auto;
    position: relative;
    width: 100%;

    @include breakpoint(medium){
        display: none;
    }
}

/* - */



// .quote
// {
//     font-family: $header-font-family;
//     font-weight: $header-font-weight;
//     font-style: $header-font-style;
//     color: $header-color;
//     line-height: $header-lineheight;
//     margin-bottom: $header-margin-bottom;
//
//     font-size: rem-calc(map-deep-get($header-styles, small, h4, font-size));
//
//     @include breakpoint(medium){
//         font-size: rem-calc(map-deep-get($header-styles, medium, h4, font-size));
//     }
// }

// Mellemrubrik
.subhead{
    font-family: $header-font-family;
    font-weight: $header-font-weight;
    font-style: $header-font-style;
    color: $header-color;
    line-height: $header-lineheight;
    margin-bottom: $header-margin-bottom;

    font-size: rem-calc(map-deep-get($header-styles, small, h4, font-size));

    @include breakpoint(medium){
        font-size: rem-calc(map-deep-get($header-styles, medium, h4, font-size));
    }

}


/* - Images - */

img,
a:not(.anchor),
.image-gallery,
.single-image
{
    &+h1,&+h2,&+h3,&+h4,&+h5,&+h6,
    &+.h1,&+.h2,&+.h3,&+.h4,&+.h5,&+.h6
    {
    //&+.paragraph-container{
        margin-top:2rem
    }
}


.paragraph-container{
    margin-top:2rem;
    margin-bottom:2rem;

}


.top-content h1,
.extra-large
{
    font-weight: 800;
    text-transform: none;
    overflow-wrap: break-word;

    font-size: rem-calc(40px);

    @include breakpoint(medium){
        font-size: rem-calc(85px);
        line-height: rem-calc(85px);
    }

    @include breakpoint(large){
        font-size: rem-calc(125px);
        line-height: rem-calc(112px);
        //padding-left: 6px; //avoid letter-cutoff
    }

}


h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6
{
    span.subline{
        font-family: $body-font-family;
        font-size: rem-calc(16px);
        display: block;
        text-transform: uppercase;
        letter-spacing: .05em;
    }
    &> span.subline{
        margin-bottom: .5em;
    }
}

/* - List - */
.inline-list{
    list-style: none;
    margin:0;
    li{
        // float: left;
        display: inline-block;
    }
}


// To open interest form in menu
#main .interest-link-buy:not(.button),
#main .interest-link-rent:not(.button)
{
    cursor: pointer;
    color: $anchor-color;
    &:hover{
        text-decoration: underline;
    }
}

.reveal{
    .interest-link-buy:not(.button),
    .interest-link-rent:not(.button) {
        color: inherit;
        &:hover{
            text-decoration: none;
        }
    }
}
