#CybotCookiebotDialogBody{
    p{
        letter-spacing: normal;
    }
    @include breakpoint(large){
        // max-width: 35% !important;
    }

    [type='checkbox'] {
        margin:auto;
    }

    [type='checkbox'] + label{
        margin-left:auto;
        margin-right: auto;
    }

}




a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll, a#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection{
    padding: 10px 8px 9px !important;
}


p.CookieDeclarationDialogText{
    font-size: rem-calc(map-deep-get($header-styles, small, h3, font-size));
    font-family: $header-font-family;
    font-weight: $header-font-weight;
    font-style: $header-font-style;
    color: $header-color;
    line-height: $header-lineheight;
    margin-bottom: $header-margin-bottom;

    @include breakpoint(medium){
        font-size: rem-calc(map-deep-get($header-styles, medium, h3, font-size));
    }
}
// .CookieDeclaration p{
//     display: none;
// }
//
// .CookieDeclaration .CookieDeclarationIntro + p{
//     display: block;
// }


#CookieBotDeclaration{
    .grid-container, .grid-x{
        padding-top: 0;
    }

    // tables
    .CookieDeclarationType{
        border: none;
        padding: 0;
    }

    .CookieDeclarationTableCell{
        padding: 9px 9px 10px 10px;
    }

    p.CookieDeclarationTypeHeader{
            margin-bottom: 0;
    }

    .CookieDeclarationTable{
        margin-top: rem-calc(map-get($grid-column-gutter, small));
        margin-bottom: rem-calc(20px);

        @include breakpoint(medium){
            margin-top: rem-calc(map-get($grid-column-gutter, medium))/2;
            margin-bottom: rem-calc(30px);
        }
    }
}
