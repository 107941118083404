.reveal{

    .close-button{
        z-index: 9999;

        top: rem-calc(30px);
        right:rem-calc(40px);

        @include breakpoint(medium){
            font-weight: bold;
            top: rem-calc(35px);
            right:rem-calc(52px);
        }
    }
}

.reveal#menu-overlay{
    padding-top: 0;
}


.reveal#signup-overlay{

    padding-top: 60px;

}
