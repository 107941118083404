// [type='text'],
// [type='password'],
// [type='date'],
// [type='datetime'],
// [type='datetime-local'],
// [type='month'],
// [type='week'],
// [type='email'],
// [type='number'],
// [type='search'],
// [type='tel'],
// [type='time'],
// [type='url'],
// [type='color'],
// textarea{
//
// }

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea{
    padding-left: 0;
}

[type='text'],
[type='password'],
[type='email'],
[type='number'],
[type='tel'],
[type='url'],
[type='color']
{
    // border-top: none;
    // border-right: none;
    // border-left: none;
    border: none;

    &:focus{
        border: none;
        // border-top: none;
        // border-right: none;
        // border-left: none;
    }
}



input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    background-color: transparent !important;
    background-image: none !important;
    color: $white !important;
}
