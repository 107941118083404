/*----------------------------------------------

B A C K G R O U N D S

------------------------------------------------*/

.primary-background
{
    background-color: $primary-color;

    // &.transparent{
    //     background-color: rgba($primary-color, 0.85);
    // }

    &.transparency-100{  background-color: rgba($primary-color, 1);     }

    &.transparency-90{   background-color: rgba($primary-color, 0.90);  }

    &.transparency-80{   background-color: rgba($primary-color, 0.80);  }

    &.transparency-70{   background-color: rgba($primary-color, 0.70);  }

    &.transparency-60{   background-color: rgba($primary-color, 0.60);  }

    &.transparency-50{   background-color: rgba($primary-color, 0.50);  }

    &.transparency-40{   background-color: rgba($primary-color, 0.40);  }

    &.transparency-30{   background-color: rgba($primary-color, 0.30);  }

    &.transparency-20{   background-color: rgba($primary-color, 0.20);  }

    &.transparency-10{   background-color: rgba($primary-color, 0.10);  }
}



.secondary-background
{
    background-color: $secondary-color;

    // &.transparent{
    //     background-color: rgba($secondary-color, 0.85);
    // }

    &.transparency-100{  background-color: rgba($secondary-color, 1);     }

    &.transparency-90{   background-color: rgba($secondary-color, 0.90);  }

    &.transparency-80{   background-color: rgba($secondary-color, 0.80);  }

    &.transparency-70{   background-color: rgba($secondary-color, 0.70);  }

    &.transparency-60{   background-color: rgba($secondary-color, 0.60);  }

    &.transparency-50{   background-color: rgba($secondary-color, 0.50);  }

    &.transparency-40{   background-color: rgba($secondary-color, 0.40);  }

    &.transparency-30{   background-color: rgba($secondary-color, 0.30);  }

    &.transparency-20{   background-color: rgba($secondary-color, 0.20);  }

    &.transparency-10{   background-color: rgba($secondary-color, 0.10);  }
}



.gray-background
{
    background-color: $light-gray;

    // &.transparent{
    //     background-color: rgba($light-gray, 0.85);
    // }

    &.transparency-100{  background-color: rgba($light-gray, 1);     }

    &.transparency-90{   background-color: rgba($light-gray, 0.90);  }

    &.transparency-80{   background-color: rgba($light-gray, 0.80);  }

    &.transparency-70{   background-color: rgba($light-gray, 0.70);  }

    &.transparency-60{   background-color: rgba($light-gray, 0.60);  }

    &.transparency-50{   background-color: rgba($light-gray, 0.50);  }

    &.transparency-40{   background-color: rgba($light-gray, 0.40);  }

    &.transparency-30{   background-color: rgba($light-gray, 0.30);  }

    &.transparency-20{   background-color: rgba($light-gray, 0.20);  }

    &.transparency-10{   background-color: rgba($light-gray, 0.10);  }
}



.white-background
{
    background-color: $white;

    // &.transparent{
    //     background-color: rgba($white, 0.85);
    // }

    &.transparency-100{  background-color: rgba($white, 1);     }

    &.transparency-90{   background-color: rgba($white, 0.90);  }

    &.transparency-80{   background-color: rgba($white, 0.80);  }

    &.transparency-70{   background-color: rgba($white, 0.70);  }

    &.transparency-60{   background-color: rgba($white, 0.60);  }

    &.transparency-50{   background-color: rgba($white, 0.50);  }

    &.transparency-40{   background-color: rgba($white, 0.40);  }

    &.transparency-30{   background-color: rgba($white, 0.30);  }

    &.transparency-20{   background-color: rgba($white, 0.20);  }

    &.transparency-10{   background-color: rgba($white, 0.10);  }
}
