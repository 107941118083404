.grid-container.project-list
{
    padding-top: 0;

    .grid-margin-x
    {
        padding-top: 0;
    }
}


.project-info
{
    a{
        display: inline-block;
        color: $body-font-color;

        &:hover .background-image,
        &:focus .background-image
        {
            transform: scale(1.1);
        }
    }

    .card{
        margin-bottom: rem-calc(map-get($grid-margin-gutters , small));

        @include breakpoint(medium){
            margin-bottom: rem-calc(map-get($grid-margin-gutters , medium));
        }

        @include breakpoint(large){
            margin-bottom: rem-calc(map-get($grid-margin-gutters , large));
        }
    }

    .card-section
    {

        padding:rem-calc(30px);

        @include breakpoint(large){
            padding:rem-calc(30px) rem-calc(60px);
        }



        span{
            font-family: $body-font-family;
            font-size: rem-calc(13px);
            display: block;
            margin-bottom: rem-calc(10px);
            text-transform: uppercase;
        }

        h1, h2, h3, h4, h5, h6{
            margin-bottom: rem-calc(30px);
        }
    }

    .button{
        min-width: auto;
    }



    // Zoom images - defaults is set in layout
    // Trigger is the a elemet above

    .image-container
    {
        height: 200px;

        @include breakpoint(medium){
            height: 320px;
        }

        @include breakpoint(xlarge){
            height: 410px;
        }
    }

    .note{
        position:absolute;
        right:-10px;
        top:50px;
        background-color:#fff;
        z-index:999;
        padding: 10px 18px;
        font-weight: bold;
        text-transform:uppercase;
    }
}


// Card Types
.news-link{

    .card{
        align-items: center;
        justify-content: center;
    }

    .card-section h1.h3{
        text-align: center;
        font-family: $body-font-family;
        font-size: rem-calc(map-deep-get($header-styles, medium, h4, font-size));
        font-weight: $global-weight-bold;

        span{
            font-weight: 400;
        }
    }
}


// Filter bar
.filter ul{
    background-color: $white;

    li{
        margin-bottom: 0;

        a{
            padding-top: rem-calc(19px);
            padding-bottom: rem-calc(19px);
            display: block;
            text-align: center;
            text-transform: uppercase;

            border: 1px solid white;
            transition: border .25s ease-out;
        }

        &.active a,
        a:hover
        {
            border: 1px solid $black;
            color: $black;
        }

        &.active a{
            font-weight: 600;
        }
    }


    // Seperator
    li:not(:first-of-type):after
    {
        content: "";
        height: 20%;
        width: 1px;
        background-color: $black;
        position: absolute;
        top:calc(50% - 10%);
        // transition: height .25s ease-out;
        // transition-delay: .2s;
    }

    li.active + li:after,
    li:hover + li:after,
    li:hover:after
    {
        height: 0;
    }
}
