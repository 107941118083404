/*----------------------------------------------

S C R O L L   T O   T O P   – Design Fordi

------------------------------------------------*/
#back-top {
    position: fixed;
    bottom:20px;
    right: 2%;
    z-index: 100;
    display: none;
    cursor: pointer;
    z-index: 9999;
    svg{
        width: 35px;
        height: 35px;
    }
}
