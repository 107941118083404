// Footer
// ----------------------

footer{
    background: $black;
    *{
        color: $white;
    }

    @include breakpoint(large){
        min-height:rem-calc(400px);
    }
}

footer .logo-container{
    @include breakpoint(medium only){
        padding-right:30px;
    }
}

footer .logo{
    display: inline-block;
    max-width: 160px;

    @include breakpoint(small only){
        margin: rem-calc(60px) 0;
    }
    @include breakpoint(large){
        margin:0 0 rem-calc(200px) 0;
    }
}

footer .cell p:last-of-type{
    @include breakpoint(medium down){
        margin-bottom: rem-calc(20px);
    }
}

footer .social-icons a svg{
    width: 35px;
    height: 35px;
}


footer .we-made-this {
    font-size: rem-calc(10px);
}
