/*
* REKA Gruppen
*/

/*TEST*/

	.no-scroll{
		overflow: hidden;
	}

	.no-scroll header{
		box-shadow: 0 0 4px 0 rgba(0,0,0,.1);
	}

	#submenu-container{
		opacity: 0;
		// position: fixed;
		position: relative;
		height: 0;
		width: 100%;
	    transition: opacity .3s,visibility .3s, height 1s ease;
	}

	.no-scroll #submenu-container,
	.no-scroll-closing #submenu-container{
		opacity: 1;
		z-index: 1000;
		transition: all .5s ease-in-out;
	}

	.no-scroll-closing #submenu-container{
		opacity: 0;
	}

	.no-scroll #submenu-container .submenu-wrapper{
		background-color: $dark-gray;
		z-index:1000;
		transition: height .5s ease;
	}


    .grecaptcha-badge{
        position: absolute;
        left: -5000px;
    }

    #emplacementRecaptcha{
        display: inline-block;
    }

	// #submenu ul > li{
	// 	a{
	// 		background-image: none;
	// 		transition: background-position .5s ease-in-out, background-size 1s ease-in-out 1s, padding-left .3s ease-in-out;
	//
	// 		background-repeat:no-repeat;
	// 		background-position: -25px center;
	// 		background-size: 18px 18px;
	// 	}
	//
	// 	&:hover a{
	// 		padding-left: 25px;
	// 		background-position: 0 center;
	// 		background-image: radial-gradient(
	// 	    circle at center,
	// 	    #949494 0,
	// 	    #949494 3px,
	// 	    $dark-gray 3px,
	// 	    $dark-gray 100%);
	// 	}
	// }


	#submenu ul {
		margin: 0;
		&> li a{
			// text-transform: uppercase;
		    letter-spacing: .03rem;//.05rem;
			font-size: rem-calc(16px);
			padding-left: 0;
			display: none;
			border-bottom: 1px solid #949494;

			transition: background-position .5s linear, padding-left .3s ease-in-out;

			background-image: radial-gradient(
			circle at center,
			#949494 0,
			#949494 3px,
			$dark-gray 3px,
			$dark-gray 100%);

			background-repeat:no-repeat;
			background-position: -25px center;
			background-size: 18px 18px;

			&:hover{
				font-weight: 600;
				color:#222;
				padding-left: 25px;
				background-position: 0 center;
			}
		}
	}

	#submenu ul li {
		min-height: 30px;
		&:last-child{
			margin-bottom: 0;
		}
	}



	// nav.nav.sub-open .cover {
	//     opacity: 1;
	//     visibility: visible;
	// }

	.no-scroll #cover-white, .no-scroll-closing #cover-white{
	    z-index: 999;
	    position: absolute;
	    background-color: rgba($white,.7);
	    // top: 0;
	    left: 0;
	    width: 100%;
	    height: 120vh;
	    display: block;
	    content: "";
	    transition: opacity .5s,visibility .5s;
	}

	.no-scroll-closing #cover-white	{
		opacity: 0;
	}


	.no-scroll .text-top-right{
		right:-100px;
	}
/* / TEST */

.page-transition .overlay,
.page-transition .page-curtain {
	width: 100vw;
	max-width: 100%;
	height: 100vh;
	opacity: 0;
	position: fixed;
	display: block;
	top: 0;
	left: 0
}

@keyframes page-transition {
	0% {
		transform: translateX(-100%)
	}
	45%,
	55% {
		transform: translateX(0)
	}
	100% {
		transform: translateX(100%)
	}
}

.page-transition {
	pointer-events: none
}

.page-transition .overlay {
	background: #ffffff;
	transition: .1s ease .5s;
	z-index: 9998
}

.page-transition .page-curtain {
	-ms-transform: translateX(-100%);
	transform: translateX(-100%);
	background: #fefefe;
	z-index: 9999
}

.page-transition .page-curtain.second {
	background: #fefefe;
	z-index: 99999
}

.page-transition.run .overlay {
	opacity: 1
}

.page-transition.run .page-curtain {
	opacity: 1;
	animation: page-transition 1.4s cubic-bezier(.77, 0, .175, 1) 0s;
	animation-fill-mode: forwards
}

.page-transition.run .page-curtain.second {
	opacity: 1;
	animation: page-transition 1.4s cubic-bezier(.77, 0, .175, 1) .2s;
	animation-fill-mode: forwards
}










.grid-container{
	@include breakpoint(small){
    	padding-left: rem-calc(map-get($grid-margin-gutters , small));
		padding-right: rem-calc(map-get($grid-margin-gutters , small));
	}
}


.flex{
    @include flex;
}

:focus{
    outline: none;
}

@include breakpoint(xxlarge){
    .grid-container.fluid {
        max-width: rem-calc(2000px);
    }
}

.is-first .grid-container:not(.background-image) {
    padding-top: 0;
}

a.anchor
{
    position: absolute;
    top: 0;
    left: 0;
}



body {
    // @include breakpoint(medium){
    //     margin-top: 55px;
    // }
}

.reveal.slide-and-fade {
  @include mui-animation(slide, fade);
}



.grid-container
{

    position: relative;

    .grid-margin-x{

        padding-top: 45px;
        padding-bottom: 0;

        @include breakpoint(medium){
            padding-top: rem-calc(120px);
            // padding-top: 190px;
            // padding-bottom: rem-calc(90)-$paragraph-margin-bottom; //90-16
        }

        // @include breakpoint(large){
        //     padding-top: 190px;
        //     padding-bottom: 120px;
        // }

    }

    // &:last-of-type() .grid-margin-x{
    //     padding-bottom:  rem-calc(130)-$paragraph-margin-bottom; //130-16
    // }



    &.same-bgcolor .grid-margin-x{
        padding-top: 0;
    }
    &.same-bgcolor:not(:last-of-type) .grid-margin-x{
        padding-bottom: 0;
    }


    &:first-child(){
        .grid-margin-x{
            padding-bottom: 0;
        }
    }

    // &:last-child(){
    //     .grid-margin-x{
    //         // padding-bottom: rem-calc(130)-$paragraph-margin-bottom; //90-16
    //         padding-bottom: rem-calc(60px);
    //     }
    // }

}


.is-first .grid-container .grid-margin-x{
    padding-top: 0;
}

.is-last .grid-container{
    padding-bottom: rem-calc(map-get($grid-margin-gutters , small));

    @include breakpoint(medium){
        padding-bottom: rem-calc(map-get($grid-margin-gutters , medium))*2;
    }

    @include breakpoint(large){
        padding-bottom: rem-calc(map-get($grid-margin-gutters , large))*2;
    }
}
// .cell{
//     position: relative;
//     //overflow: hidden;
//     //z-index: 99;
//
//     padding-bottom: rem-calc(map-get($grid-margin-gutters , small));
//
//     @include breakpoint(medium){
//         padding-bottom: rem-calc(map-get($grid-margin-gutters , medium));
//     }
//
//     @include breakpoint(large){
//         padding-bottom: rem-calc(map-get($grid-margin-gutters , large));
//     }
//
//
//     // @include breakpoint(small only){
//     //     &:last-child{
//     //         padding-bottom: 0;
//     //     }
//     //
//     // }
//
// }

.cell{
    position: relative;
//
//     padding-bottom: rem-calc(map-get($grid-margin-gutters , small));
//
//     @include breakpoint(medium){
//         padding-bottom: rem-calc(map-get($grid-margin-gutters , medium));
//     }
//
//     @include breakpoint(large){
//         padding-bottom: rem-calc(map-get($grid-margin-gutters , large));
//     }
//
//     &:last-of-type{
//         padding-bottom: 0;
//     }

	&.small-12{
		@include breakpoint(medium down){
			margin-bottom: rem-calc(map-get($grid-margin-gutters , medium));
		}
	}

}

@include breakpoint(large){
    .half-column.image-right .grid-container{
        padding-left:0;
    }

    .half-column.image-left .grid-container{
        padding-right:0;
    }
}



.grid-container
{
    padding-top: rem-calc(map-get($grid-margin-gutters , small));
    padding-bottom: rem-calc(map-get($grid-margin-gutters , small));

    @include breakpoint(medium){
        padding-top: rem-calc(map-get($grid-margin-gutters , medium));
        padding-bottom: rem-calc(map-get($grid-margin-gutters , medium));
    }

    @include breakpoint(large){
        padding-top: rem-calc(map-get($grid-margin-gutters , large));
        padding-bottom: rem-calc(map-get($grid-margin-gutters , large));
    }
}

.grid-container.full
{
    padding-left: rem-calc(map-get($grid-margin-gutters , small));
    padding-right: rem-calc(map-get($grid-margin-gutters , small));

    @include breakpoint(medium){
        padding-left: rem-calc(map-get($grid-margin-gutters , medium));
        padding-right: rem-calc(map-get($grid-margin-gutters , medium));
    }

    @include breakpoint(large){
        padding-left: rem-calc(map-get($grid-margin-gutters , large));
        padding-right: rem-calc(map-get($grid-margin-gutters , large));
    }
}



// Add frame to image cells if grid-container is fullscreen
// .grid-container.full .grid-x .cell.with-image:first-of-type
// {
//     // @include breakpoint(medium){
//     //     padding-left: rem-calc(map-get($grid-margin-gutters , medium));
//     // }
//
//     @include breakpoint(large){
//         padding-left: rem-calc(map-get($grid-margin-gutters , large));
//     }
// }


// .grid-container.full .grid-x .cell:nth-last-of-type(even)
// {
//     @include breakpoint(large){
//         padding-right: rem-calc(map-get($grid-margin-gutters , large));
//     }
// }



.reveal.dotted
{
    background:
        linear-gradient(to right, $medium-gray 60px, $medium-gray 60px), //bjælke i højre side
        radial-gradient(#a1a1a1 7%, $medium-gray 0%);

    background-position:
        100% 0,
        25px 45px;

    background-size:
        100px 100%,
        80px 80px;

    background-repeat:
        no-repeat,
        repeat;

}

.dotted
{
    // background:
    //     radial-gradient(#e1e1e1 7%, transparent 7%),
    //     radial-gradient(#e1e1e1 7%, transparent 7%),
    //     transparent;
    //
    // background-position: 0 0, 40px 40px;
    // background-size:80px 80px;

    // background: radial-gradient(#e1e1e1 7%, transparent 7%);
    //     background-position: 25px 45px;
    //     background-size: 80px 80px;

    background:
        linear-gradient(to right, transparent 60px, $light-gray 60px), //bjælke i højre side
        radial-gradient($dark-gray 7%, transparent 0%);

    background-position:
        100% 0,
        25px 45px;

    background-size:
        100px 100%,
        80px 80px;

    background-repeat:
        no-repeat,
        repeat;

}



.paragraph-container
{
    @include breakpoint(large){
        &.small{
            width: 33%;
        }
        &.medium{
            width: 50%;
        }
        &.large{
            width: 75%;
        }
    }
}



// 4. Base Typography
// ------------------

// IS ADDED IN _editor.scss



// 11. Button
// ----------------------

//p+.button-container
.button-container
{
    padding-top: rem-calc(map-get($grid-margin-gutters , small));

    @include breakpoint(medium){
        padding-top: rem-calc(map-get($grid-margin-gutters , medium));
    }

    @include breakpoint(large){
        padding-top: rem-calc(map-get($grid-margin-gutters , large));
    }
}


// .cell + .cell + .button-container-cell .button-container {
//     padding-top: 0;
// }



.button-container a,
.button-container button
{
    margin-top: rem-calc(20px);
    margin-right:rem-calc(20px);

    &:last-child {
        margin-right: 0;
    }

    &:not(.button) {
        padding: $button-padding;
    }
}


.button,
.button-container a,
.button-container button
{
    display: inline-block;
    text-transform: uppercase;
    font-weight: $global-weight-bold;
    letter-spacing: .1em;
	min-width: 100%;

	@include breakpoint(medium){
		min-width: rem-calc(150px);
	}
	@include breakpoint(large){
		min-width: rem-calc(250px);
	}
}


.button.secondary
{
    border-width:$button-hollow-border-width;
    border-style:solid;
    border-color: $white;
}

.button.with-stroke{
    border:1px solid $medium-gray;
}

// .primary-background,
// .transparent-background,
// .white-background
// {
//     .button{
//         border:2px solid $white;
//     }
//
//     .button:hover,
//     .button:focus {
//         border:2px solid $white;
//     }
// }


// .secondary-background
// {
//     .button.secondary,
//     button.button.secondary
//     {
//         border:2px solid $white;
//
//         &:hover,
//         &:focus {
//             border:2px solid $white;
//         }
//     }
// }

//
// .primary-background .button-container{
//
//     a:not(.button),
//     button:not(.button)
//     {
//         color: $white;
//     }
// }


.button.fill-area {
    width: 100%;
}



// Backgrounds
// ----------------------

.primary-background{
    p, li, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, td, label{
        color: $white;
    }
}


// Top Content
// ----------------------

.top-content{

    margin-top: 50px;

    @include breakpoint(medium)
    {
        margin-top: 90px;

        padding-bottom: 150px;

        &.with-intro-text{
            padding-bottom: 200px;
        }

        .cell{
            padding-bottom: 0;
        }
    }
}


.top-content .page-heading
{
    color: $white;
    z-index: 999;
}


.top-content.image,
.top-content.video
{
    .page-heading
    {
        position: absolute;
        left: 8%;
        width: 84%; //100%-8%-8% (the sides = 8%)
    }

    &.with-intro {
        @include breakpoint(medium){
            padding-bottom: rem-calc(210px);
        }
    }
}


.top-content.image .page-heading
{
    top:rem-calc(30px);

    @include breakpoint(medium){
        &.top{
            top: -5%;
            bottom: auto;
        }

        &.bottom{
            top: auto;
            bottom: 15%;
        }

        &.middle{
            top: 50%;
            transform: translateY(-50%);
            bottom: auto;
        }
    }
}


// page-heading is always in top when it's a video
.top-content.video .page-heading
{
    transform: translateY(-50%);
}


// .top-content .scroll-down
// {
//     display: none;
//
//     @include breakpoint(medium)
//     {
//         display: block;
//         width: 70px;
//         height: 15px;
//
//         position: absolute;
//         bottom: 40px; //200px;
//         left: 50%;
//
//         transform: translateX(50%) rotate(90deg);
//
//         background-image: url(../img/icons/arrow-white.svg);
//         background-repeat: no-repeat;
//         background-position: center;
//     }
// }



.intro-text
{

    background-color: $white;
    padding: 40px;

    p{
        margin-bottom: 0;
        font-family: $button-font-family;
        font-style: italic;
    }

    @include breakpoint(medium)
    {
        padding: 60px;

        width: 450px;
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
        left: 8%;

        overflow: hidden;
        // max-height: 40ch;

        p{
            font-size: rem-calc(25px);
            line-height: rem-calc(30px);
        }
    }
}





.icon-gps-point
{
    background-image: url(../img/icons/icon-gps-point-black.svg);
    background-size: 22.8px 29.8px;

    &:hover{
        background-image: url(../img/icons/icon-gps-point-white.svg);
    }
}


.icon-area-map
{
    background-image: url(../img/icons/icon-area-map-black.svg);
    background-size: 25.6px 26.8px;

    &:hover{
        background-image: url(../img/icons/icon-area-map-white.svg);
    }
}


.icon-floor-plan
{
    background-image: url(../img/icons/icon-floor-plan-black.svg);
    background-size: 25.69px 25.6px;

    &:hover{
        background-image: url(../img/icons/icon-floor-plan-white.svg);
    }

    &+ .icon-floor-plan
    {
        display: none;
    }

}



.top-content{
    & + #main .transparent-background.is-first .grid-container .grid-margin-x{
        padding-top: 0;
    }
}



// Forms
// ----------------------
form .error {

	opacity: 0;
    transition: opacity 2s;

	p{
	    background-color: get-color(alert);
		color: $white;
		font-weight: 600;
		text-align: center;
		// @include breakpoint(medium) {
	    //   @include xy-cell(4);
	    // }
		width: 250px;
		// @include flex-align-self(center);

	}

	// @include xy-cell();

@include flex;
@include flex-align(center, middle);

	// @include flex-align-self(middle);
}



// Shortcut button, right sides
// ----------------------
.text-top-right{
    position: absolute;
    right: rem-calc(-35px);
    top: 140px;
    transform: rotate(90deg);
    margin: 0;
    text-transform: uppercase;
    font-size:rem-calc(9px);
    letter-spacing: .01rem;
	transition: right .3s ease-in-out;
}



// Heights
// ----------------------
@include breakpoint(medium){
	.vh100{ min-height:100vh; }
	.vh90{ min-height:90vh; }
	.vh80{ min-height:80vh; }
	.vh70{ min-height:70vh; }
	.vh60{ min-height:60vh; }
	.vh50{ min-height:50vh; }
	.vh40{ min-height:40vh; }
	.vh30{ min-height:30vh; }
	.vh20{ min-height:20vh; }
	.vh10{ min-height:10vh; }
}

// @include breakpoint(medium down){
// 	.vh100,
// 	.vh90,
// 	.vh80,
// 	.vh70,
// 	.vh60,
// 	.vh50,
// 	.vh40,
// 	.vh30,
// 	.vh20,
// 	.vh10{
// 		min-height:30vh;
// 	}
// }

// .vh100,
// .vh90,
// .vh80,
// .vh70,
// .vh60,
// .vh50,
// .vh40,
// .vh30,
// .vh20,
// .vh10{
//     @include breakpoint(medium down){
//         min-height: auto;
//         height: auto;
//     }
// }

@include breakpoint(medium down){
	//#map_canvas{
	div[id*="map_canvas"] {
		min-height: 60vh;
	}
}

// .is-first{
//      .vh80{
//          height:70vh;
//          min-height: auto;
//      }
//
//      .vh90{
//
//      }
//
//      .vh100{
//
//      }
//  }


/* //////////////////////// OBJECT FIT //////////////////////// */

.cell.fit-image{
    height: auto;
    padding-left: 0;
    padding-right: 0;
}

.fit-image img {
    object-fit: cover;
    font-family: 'object-fit: cover;';
    width: 100%;
    // min-height: 320px;
    height: 100%;

    @include breakpoint(large){
        min-height: 650px;
    }
}


/**********************
****  Inline List  ****
**********************/


ul.inline-list{
    margin-left: 0;
    list-style: none;

    li{
        display: inline-block;
    }

}


/************************
****  Page settings  ****
************************/


.page-header{
    padding-bottom: 0;
}


/****************
****  ICONS  ****
****************/
.social-icons{
    a{
        margin-right: rem-calc(10px);
        &:last-of-type{
            margin-right:0;
        }
    }
}



/**********************
****  Zoom images  ****
**********************/

.image-container
{
    position: relative;
    display: block;
    width: 100%;
    height: 300px;
    overflow: hidden;


    .background-image
    {
        display: block;
        width: 100%;
        height: 100%;
        background-color: $dark-gray; /* fallback color */
        background-position: center;
        background-size: cover;

        transition: all 2s;
    }


    // Trigger Should be set indiviually
    // on parent element

    // &:hover .background-image,
    // &:focus .background-image
    // {
    //     transform: scale(1.2);
    // }

}

[data-aos="fade-scroll-out"] {
  opacity: 1 !important;
  transition-property: opacity;
  &.aos-animate {
    opacity: 0 !important;
  }
}
