/*************
* List-section
*************/

.list-section .cell > .grid-x .cell:last-child
{
    padding-bottom: 0;
}

@include breakpoint(medium)
{
    .list-section > .cell
    {
        padding-bottom: 0;
    }
}
