.textbg
{
    padding: 30px;
    background: #fff;

    .button{
        border:1px solid $medium-gray;
    }
}

.highlighted-section
{
    padding: rem-calc(map-get($grid-margin-gutters , small));

    @include breakpoint(medium){
        padding: rem-calc(map-get($grid-margin-gutters , medium));
    }

    @include breakpoint(large){
        padding: rem-calc(map-get($grid-margin-gutters , large));
    }
}

.highlighted-section + .highlighted-section{
    padding-top:0;

    @include breakpoint(medium){
        margin-top: -(rem-calc(map-get($grid-margin-gutters , medium)));
    }

    @include breakpoint(large){
        margin-top: -(rem-calc(map-get($grid-margin-gutters , large)));
    }
}

.highlighted-section .background-image
{
    background-repeat: no-repeat;

    &.contain{
        background-size: contain;
        background-position: center;
    }

    &.cover{
        background-size: cover;
        background-position: center;
    }


    &.fixed{
        background-attachment: fixed;
        @include breakpoint(medium down){
            // Fixed does not work on ios devices!
            background-attachment:scroll;
        }
    }

    // &.background-position-right{
    //     background-position-x: right;
    // }
    //
    // &.background-position-center{
    //     background-position-x: center;
    // }

    &.o-p-left-top{
        background-position: left top;
    }

    &.o-p-left-center{
        background-position: left center;
    }

    &.o-p-left-bottom{
        background-position: left bottom;
    }

    &.o-p-center-top{
        background-position: center top;
    }

    &.o-p-center-center{
        background-position: center center;
    }

    &.o-p-center-bottom{
        background-position: center bottom;
    }

    &.o-p-right-top{
        background-position: right top;
    }

    &.o-p-right-center{
        background-position: right center;
    }

    &.o-p-right-bottom{
        background-position: right bottom;
    }

    @include breakpoint(medium down){
        min-height:200px;
    }

}
