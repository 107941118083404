.image-cell{
    z-index: 999; /* To have slideshow controls on top */
}

.object-position-top{
    object-position: top;
}

.object-position-right{
    object-position: right;
}

.object-position-bottom{
    object-position: bottom;
}

.object-position-left{
    object-position: left;
}


.o-p-left-top{
    object-position: left top;
}

.o-p-left-center{
    object-position: left center;
}

.o-p-left-bottom{
    object-position: left bottom;
}

.o-p-center-top{
    object-position: center top;
}

.o-p-center-center{
    object-position: center center;
}

.o-p-center-bottom{
    object-position: center bottom;
}

.o-p-right-top{
    object-position: right top;
}

.o-p-right-center{
    object-position: right center;
}

.o-p-right-bottom{
    object-position: right bottom;
}
