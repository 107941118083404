/*----------------------------------------------

Notification

------------------------------------------------*/

#notification
{
    display: none;
    position: fixed;
    z-index: 99999;

    background-color: $white;

    font-weight: bold;


    @include breakpoint(small only){
        display: none;
        bottom: 0;
        // left: 0;
        left: 0;
    }



    @include breakpoint(medium){
        max-width:150px;
        left: -500px;
        top: 200px;

        &:hover{
            background-color: $dark-gray;//scale-color($black, 80)
        }
    }

}

#notification .badge{
    position: absolute;
    right: -10px;
    top: -10px;
    font-weight: 800;
    transform:scale(0);
}

#notification .badge.anim{
    transform:scale(1);
    transition: all 0.3s cubic-bezier(0.600, 0.000, 0.200, 1.000);
    transition-timing-function: cubic-bezier(0.600, 0.000, 0.200, 1.000);
    transition-delay: 1s;
}


#notification-link{

    display: block;
    // background-color: #fff;
    padding:10px 10px 10px 30px;
    font-size: rem-calc(15px);
    text-transform: uppercase;
    color: $black;
    transition: padding 150ms ease-out;

    &:hover{
        padding-left: 40px;
    }
}


#notification .close{
    position: absolute;
    cursor: pointer;
    font-family: "Arial", sans-serif;
    font-size: rem-calc(21px);
    padding:10px 12px;
    font-weight: 100;
    line-height: 1;

    &:hover{
        background-color: get-color(alert);
        color: $white;

        + #notification-link{
            padding-left: 50px;
        }
    }
    // display: flex;
    // position: absolute;
    // cursor: pointer;
    // font-family: "Arial", sans-serif;
    // padding: 12px 6px 9px; //9px 5px 11px;
    // border-radius: 15px;
    //
    // @include breakpoint(small only){
    //     top: 10px;
    //     right: 10px;
    //     font-size: 2rem;
    //     font-weight: normal;
    //     padding: 20px 11px 20px;
    //     border-radius: 30px;
    // }
    //
    //
    // @include breakpoint(medium){
    //     // display: none;
    //     // top: -6px;
    //     right: auto;
    //     // left: -6px;
    //     border:1px solid $white;
    //
    //     &:hover{
    //         background-color: scale-color($black, 80)
    //     }
    // }
    //
    //
    // // width: 18px;
    // // height: 18px;
    //
    //
    //
    // align-items: center;
    // justify-content: center;
    //
    // background-color: $black;
    // box-shadow: 15px 15px 45px 0px rgba(10, 10, 10, 0.12); //0px 7px 20px -4px rgba(0,0,0,0.3);
    //
    // span{
    //     // position: absolute;
    //     // display: block;
    //     line-height: 0;
    //     // letter-spacing: 2px;
    //
    // }
}


#notification:hover .close{
    display: flex;
}



// .have-notification{
//     &:after{
//         position: absolute;
//         top: 20px;
//         left: 15px;
//         width: 18px;
//         height: 18px;
//         border-radius: 9px;
//         background-color: $alert-color;
//
//         display: flex;
//         justify-content: center;
//         align-items: center;
//
//         content: "1";
//         font-size: .8em;
//         line-height: 0;
//
//         transition: transform 0.3s cubic-bezier(0.600, 0.000, 0.200, 1.000);
//
//         z-index: 999;
//         transform: scale(0);
//
//         @include breakpoint(medium){
//             top: -6px;
//             left: -6px;
//         }
//     }
//
// }


// @include breakpoint(medium){
//     .have-notification:hover{
//         &:after{
//             display: none;
//         }
//
//     }
// }


html.loaded .have-notification:after{
    transform: scale(1);
	transition-delay: 2s;
}

html.loaded .is-off-canvas-open #notification{
    background-color: scale-color($primary-color, 80);
}
